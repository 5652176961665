<template>
  <table class="permissions-table">
    <thead>
      <tr>
        <th class="entity-header">Entity</th>
        <th>Action</th>
        <th
          v-for="role in Object.values(RoleEnum)"
          :key="role"
          class="role-header"
        >
          {{ getRoleName(role) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="entity in sortedEntities">
        <tr
          v-for="(action, index) in sortedActions(entity)"
          :key="`${entity}-${action}`"
        >
          <td
            v-if="index === 0"
            :rowspan="Object.keys(innerValue[entity]).length"
            class="entity-cell"
          >
            {{ getEntityName(entity) }}
          </td>
          <td>
            {{ getActionName(entity, action) }}
          </td>
          <td
            v-for="role in Object.values(RoleEnum)"
            :key="role"
            class="role-cell"
          >
            <div class="role-cell__container">
              <Checkbox
                :value="innerValue[entity][action].includes(role)"
                :variant="isDisabled ? 'secondary' : undefined"
                :is-disabled="isDisabled"
                @input="handlePermissionChange(entity, action, role, $event)"
              />
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { RoleEnum } from "@/helpers/enums";
import {
  ACTION_NAMES_MAP,
  ENTITY_NAMES_MAP,
  ROLE_NAMES_MAP,
} from "@/helpers/const";

export default {
  name: "PermissionsTable",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      RoleEnum,
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    sortedEntities() {
      return Object.keys(this.innerValue).sort((a, b) =>
        this.getEntityName(a) > this.getEntityName(b) ? 1 : -1
      );
    },
  },
  methods: {
    getRoleName(role) {
      return ROLE_NAMES_MAP[role];
    },
    getEntityName(entity) {
      return ENTITY_NAMES_MAP[entity] || entity;
    },
    getActionName(entity, action) {
      return ACTION_NAMES_MAP?.[entity]?.[action] || action;
    },
    sortedActions(entity) {
      return Object.keys(this.innerValue[entity]).sort((a, b) =>
        this.getActionName(entity, a) > this.getActionName(entity, b) ? 1 : -1
      );
    },
    handlePermissionChange(entity, action, role, isActive) {
      const roles = this.innerValue[entity][action];
      const copy = JSON.parse(JSON.stringify(this.innerValue));
      copy[entity][action] = isActive
        ? [...new Set([...roles, role])]
        : roles.filter((item) => item !== role);
      this.innerValue = copy;
    },
  },
};
</script>

<style lang="scss" scoped>
.permissions-table {
  width: 100%;

  thead {
    th {
      padding-bottom: 9px;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: $secondary-500;
      text-align: left;
      text-transform: none;
      border-bottom: 1px solid $secondary-300;
    }
  }

  tbody {
    td {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $secondary-500;
      text-align: left;
      text-transform: none;
      padding: 20px 0;
      border-bottom: 1px solid $secondary-300;
    }
  }

  .entity-header {
    width: 20%;
  }

  .role-header {
    width: 12%;
    text-align: center;
  }

  .entity-cell {
    width: 180px;
    vertical-align: top;
  }

  .role-cell {
    width: 12%;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: $primary !important;
      }
    }
  }
}
</style>
