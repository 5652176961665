<template>
  <div class="permissions-page">
    <BackTitle class="mb-40" @click="goBack"> Permissions </BackTitle>
    <Form v-if="permissions" v-slot="{ changed }">
      <FormItem>
        <PermissionsTable
          v-model="permissions"
          :is-disabled="!isOwner"
          class="mb-64"
        />
      </FormItem>
      <Button
        v-if="isOwner"
        :is-disabled="!changed"
        :is-block="['xs', 'sm'].includes($mq)"
        :is-loading="isSubmitting"
        @click="handleSubmit"
      >
        Save
      </Button>
    </Form>
    <Loader v-else class="mx-auto" size="m" color="primary" />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import PermissionsTable from "@/components/permissions/PermissionsTable";
import defaultPermissions from "@/assets/permissions.json";
import { mapActions, mapState } from "vuex";
import { RoleEnum } from "@/helpers/enums";
import dialog from "@/plugins/dialog";

export default {
  name: "PermissionsPage",
  components: { PermissionsTable, BackTitle },
  data() {
    return {
      isSubmitting: false,
      permissions: null,
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      isOwner: (state) =>
        state.users.venueUserJunction?.role === RoleEnum.OWNER,
    }),
  },
  watch: {
    venue() {
      this.fetchPermissions();
    },
  },
  created() {
    window.scrollTo({
      top: 0,
    });
    this.fetchPermissions();
  },
  methods: {
    ...mapActions({
      updateVenue: "venues/updateVenue",
      fetchVenue: "venues/fetchVenue",
    }),
    fetchPermissions() {
      if (this.venue) {
        this.permissions = JSON.parse(
          JSON.stringify(this.venue.permissions || defaultPermissions)
        );
      } else {
        this.permissions = null;
      }
    },
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    async handleSubmit() {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: "Changes to permissions can have unpredictable consequences",
        okText: "Yes",
        cancelText: "Cancel",
      });

      if (confirmed) {
        try {
          this.isSubmitting = true;
          await this.updateVenue({
            id: this.venue.id,
            data: {
              permissions: this.permissions,
            },
          });
          await this.fetchVenue();
          this.goBack();
        } finally {
          this.isSubmitting = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.permissions-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
